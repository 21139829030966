import Vue from 'vue';

import Router from 'vue-router';
Vue.use(Router);

import { VIEW } from '@master/constants';
import preview from '@root/src/router/preview';

const router = new Router({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  routes: preview,
});

router.beforeEach((to, _from, next) => {
  const whitelabel = Vue.prototype.$whitelabel;
  document.title = `${whitelabel?.title ?? 'NEXD'} | ${to.meta.app_name}`;

  if (to.path.includes(VIEW.PREVIEW.CLICKURL) && to.name !== VIEW.PREVIEW.CLICKURL) {
    return next({ name: VIEW.PREVIEW.CLICKURL });
  }

  if (to?.params?.state) {
    if (to.params.state !== 'creative' && to.params.state !== 'live') {
      to.params.state = 'creative';
      return next(to);
    }
  }

  next();
});

export default router;
