<template lang="pug">
div#content
  div.preview(:class="{'single-creative': !$route.params.campaign_id && !isQuantum() && !isHash(), 'disable-scroll': userHasMobile() && isFullscreen()}")
    span.preview-navbar-gyro-controller(v-if="userHasMobile() && creative.has_gyro && show_mobile_gyro_toggle" @click="$parent.toggleGyro")
      i(:class="`nexd-icon-32-gyro-${gyro_enabled ? 'on' : 'off'}`" aria-hidden="true")

    PreviewHeader(v-if="!userHasMobile()" :creative="creative" ref="previewheader")
      PreviewOptions(:creative="creative" :options="device_options" @change="handleDeviceOptionChange" @reload="reload")

    div.creative-preview(v-if="creative" :class="mobile_view ? 'mobile-preview' : 'desktop-preview'")
      h1.creative-mobile-title {{ mobile_title }}
      QuantumPreview(v-if="isQuantum() && !$route.hash" :creative="creative")
      LivePreview(v-else :key="preview_key" ref="preview" :creative="creative" :clickable="true" :options="preview_options" :poll="false" :height="preview_height")

      div.qr-preview(v-if="!userHasMobile()" :class="{'mobile': mobile_view}")
        div(v-if="can_show_qr_code")
          QRCode(v-if="!qr_load_error" :label="false" :key="qr_code_url" :url="qr_code_url")
          div.qr-code-error.fs-14(v-else) Oops! Error generating QR code, please
            br
            span.mt-8.color-primary(@click="getQRCodeUrl") try again.
        div.qyro-container(v-if="mobile_view && creative.has_gyro")
          span
            span.fs-14 Controls
            i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{value: 'Mimic phone movement by moving the device below.'}")
          GyroController.mb-32(@ready="initGyro" ref="gyro")
</template>

<script>
import { SIZE } from '@master/constants';
import { searchLongStringsAndBreak } from '@helpers/Global';
import QRService from '@services/QRService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

import GyroController from '@master/UI/GyroController.vue';
import LivePreview from '@master/UI/LivePreview/LivePreview.vue';
import QRCode from '@master/UI/QRCode.vue';

import QuantumPreview from '@components/preview/QuantumPreview.vue';
import PreviewHeader from '@components/preview/header/PreviewHeader.vue';
import PreviewOptions from '@components/preview/header/PreviewOptions.vue';

export default {
  name: 'CreativePreview',

  mixins: [CreativeTraits, Mixins],

  components: {
    GyroController,
    LivePreview,
    QRCode,
    QuantumPreview,
    PreviewHeader,
    PreviewOptions,
  },

  props: {
    creative: {
      type: Object,
      default: null,
    },

    show_mobile_gyro_toggle: {
      type: Boolean,
      default: true,
    },

    gyro_enabled: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    device_options() {
      return {
        toggle_device: this.toggle_device,
        toggle_browser_bars: this.toggle_browser_bars,
        toggle_high_aspect_ratio: this.toggle_high_aspect_ratio,
        toggle_safeframe: this.toggle_safeframe,
      };
    },

    can_show_qr_code() {
      if (this.userHasMobile() || !this.show_qr_code) {
        return false;
      }
      // show eiter qr code or error message with try again button
      return this.qr_code_url != null || this.qr_load_error;
    },

    preview_height() {
      if (this.isDesktop(this.toggle_device)) {
        return '100%';
      }
      // Even if screen is ultra tall, preview mobile device will not be taller than 1280px
      return `${Math.min(1280, Math.round(window.innerHeight / 1.45))}px`;
    },

    preview_key() {
      let key = this.toggle_device;
      if (this.isVideo()) {
        // video dimensions are important for video creatives
        key += `_${this.creative.width}x${this.creative.height}_${this.max_video_height}`;
      } else if (this.isMobile(this.preview_options.force_placement_device)) {
        // high aspect ratio toggle
        key += `_${this.preview_options.screen_height}`;
      } else if (this.isQuantum()) {
        key += `_${this.$route.hash}`;
      }

      if (this.preview_options.force_placement_device != null) {
        key += `_${this.preview_options.force_placement_device}`;
      }
      if (this.preview_options.safeframe != null) {
        key += `_${this.preview_options.safeframe}`;
      }
      if (this.preview_options.browser_bars != null) {
        key += `_${this.preview_options.browser_bars}`;
      }
      if (this.isLiveCreative()) {
        key += '_live';
      }
      return key;
    },

    preview_options() {
      let options = {};

      if (!this.isVideo()) {
        options.force_placement_device = this.toggle_device;
      }

      if (this.isLiveCreative()) {
        options.live_id = this.creative.live_id;
        options.wait_for_version = false;
      } else {
        options.creative_id = this.creative.creative_id;
      }

      if (this.isQuantum()) {
        const info = this.$route.hash.slice(1).split('x');
        if (info[0] != null && info[1] != null && !isNaN(info[0]) && !isNaN(info[1])) {
          options.force_placement_width = parseInt(info[0], 10);
          options.force_placement_height = parseInt(info[1], 10);
          options.force_placement_device = 1;

          if (info[2] == null) info[2] = 'D';

          if (info[2] === 'M') {
            // disabled those lines, mobile quantum should not have changed size values (it will mess up FW thinking diffferent sizes)
            // const ratio = options.force_placement_width / 480;
            // options.force_placement_width /= ratio;
            // options.force_placement_height /= ratio;
            options.force_placement_device = 0;
          }

          options.query_params = [`sw=${options.force_placement_width}`, `sh=${options.force_placement_height}`];
        }
      }

      if (this.userHasMobile()) {
        options.device = false;
        options.iframe = false;
        options.full_height = true;

        // if on mobile view, skip the rest and return settings
        return options;
      }

      if (this.isMobile(this.toggle_device)) {
        if (this.toggle_high_aspect_ratio) {
          options.screen_height = null;
        } else {
          options.screen_height = 854;
        }
      }

      options.browser_bars = this.toggle_browser_bars;
      options.safeframe = this.toggle_safeframe;

      if (this.isDesktop(options.force_placement_device)) {
        options.device = false;
        options.iframe = false;
      }

      if (this.isVideo() && this.max_video_height) {
        options.max_height = this.max_video_height;
      }

      options.loading = false;

      return options;
    },

    mobile_view() {
      if (this.isQuantum()) {
        if (!this.$route.hash) {
          return false;
        }

        const info = this.$route.hash.slice(1).split('x');
        return info[2] != null && info[2] === 'M';
      }

      return this.userHasMobile() || (this.isMobile(this.toggle_device) && !this.isVideo());
    },

    mobile_title() {
      return searchLongStringsAndBreak(this.creative.name, 10);
    },
  },

  data() {
    let toggle_device = this.creative.settings.preview_device;
    let toggle_high_aspect_ratio = this.isMobile(this.creative.settings.preview_device);

    if (this.isFullscreen()) {
      toggle_high_aspect_ratio = this.creative.height === SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;
    }

    if (this.isQuantum() && this.$route.hash) {
      toggle_device = 1;
      const info = this.$route.hash.slice(1).split('x');
      if (info[2] != null && info[2] === 'M') {
        toggle_device = 0;
        toggle_high_aspect_ratio = true;
      }
    }

    return {
      toggle_high_aspect_ratio,
      toggle_device,
      toggle_safeframe: false,
      toggle_browser_bars: true,
      max_video_height: null,

      show_qr_code: false,
      qr_code_url: null,
      qr_load_error: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.toggle_browser_bars = this.$user.isBrowserFrameEnabled();
      }
    }, this);

    this.getQRCodeUrl();
    QRService.subscribe(state => {
      this.show_qr_code = state.show;
      this.qr_code_url = state.qr;
      this.qr_load_error = state.error;
    }, this);
  },

  mounted() {
    if (this.$refs.previewdetails && this.$refs.previewheader) {
      this.max_video_height = window.innerHeight - 128 - this.$refs.previewdetails.$el.clientHeight - this.$refs.previewheader.$el.clientHeight;
    }
  },

  methods: {
    getQRCodeUrl() {
      QRService.get();
    },

    initControls() {
      if (this.$refs.preview != null) this.$refs.preview.initControls();
    },

    initGyro() {
      if (this.$refs.preview != null) this.$refs.preview.initGyro();
    },

    reload() {
      if (this.$refs.preview != null) this.$refs.preview.reload();
    },

    reloadDevice() {
      if (this.$refs.preview != null) this.$refs.preview.reloadDevice();
    },

    toggleDevice(value) {
      this.toggle_device = value ?? 0;
      if (this.$route.hash !== '') {
        let device = 'D';
        if (this.toggle_device === 0) {
          device = 'M';
        }
        const info = this.$route.hash.split('x');
        this.$router.push({ hash: `${info[0].slice(1)}x${info[1]}x${device}` });
      }

      if (this.toggle_device === 0) {
        this.toggle_high_aspect_ratio = true;
      }
    },

    handleDeviceOptionChange(key, value) {
      if (key === 'toggle_device') {
        return this.toggleDevice(value);
      }

      this[key] = value;
      this.reloadDevice();
    },
  },
};
</script>
