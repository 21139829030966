import '@root/src/styles/main.scss';

import Vue from 'vue';

import router from './router/Router';

import GlobalServicePlugin from '@master/Plugins/GlobalServicePlugin';
Vue.use(GlobalServicePlugin, router);

import NexdFilters from '@master/Plugins/NexdFilters';
Vue.use(NexdFilters);

import NexdDirectives from '@master/Plugins/NexdDirectives';
Vue.use(NexdDirectives);

import AlertPlugin from '@master/Plugins/AlertPlugin';
Vue.use(AlertPlugin);

import ServicePlugin from '@services/ServicePlugin';
Vue.use(ServicePlugin, router);

import App from './App.vue';
function run() {
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
}

import WhitelabelService from '@master/Services/WhitelabelService';
import constants from '@master/constants';

constants.ready(_ => {
  WhitelabelService.ready(whitelabel => {
    Vue.prototype.$whitelabel = whitelabel;
    run();
  });
});
