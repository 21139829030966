<template lang="pug">
div.feedback-icons.flex.column-gap-8(v-if="has_feedback" @click="openComments")
  i.nexd-icon-16-comments(v-if="has_comments" :data-count="creative.feedback_comment_count" aria-hidden="true")
  div(v-if="has_comments && creative.feedback_status !== FEEDBACK.PENDING")
  i.nexd-icon-16-check-mini.approve(v-if="creative.feedback_status === FEEDBACK.APPROVED" aria-hidden="true")
  i.nexd-icon-16-close-small.reject(v-else-if="creative.feedback_status === FEEDBACK.REJECTED" aria-hidden="true")
</template>

<script>
import { FEEDBACK } from '@master/constants';
import FeedbackService from '@services/FeedbackService';

export default {
  name: 'FeedbackIcons',

  props: {
    creative: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    has_feedback() {
      return this.has_comments || this.has_status;
    },

    has_status() {
      return [FEEDBACK.APPROVED, FEEDBACK.REJECTED].includes(this.creative.feedback_status);
    },

    has_comments() {
      return this.creative.feedback_comment_count > 0;
    },
  },

  data() {
    return {
      FEEDBACK,
    };
  },

  methods: {
    openComments() {
      FeedbackService.state.toggle();
    },
  },
};
</script>
