<template lang="pug">
SidebarContainer.preview-sidebar
  template(v-if="folder != null || campaign != null")
    SidebarItem(:label="folder?.name ?? campaign?.name" :stateless="true")
    hr

  SidebarSubitems.mt-8(v-if="creatives != null" :grow="true")
    template(v-for="(creative, index) of creatives")
      Row(:key="index" :creative="creative" :type="creative.type")

      template(v-if="isQuantum(creative?.type ?? 0)" v-for="cre of splitQuantum(creative)")
        Row(:key="cre.id" :creative="cre" :type="creative.type")
</template>

<script>
import { TYPE_STRINGS } from '@master/constants';
import CreativesService from '@services/CreativesService';
import { tmp } from '@_helpers';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

import SidebarContainer from '@master/UI/Sidebar/SidebarContainer.vue';
import SidebarItem from '@master/UI/Sidebar/SidebarItem.vue';
import SidebarSubitems from '@master/UI/Sidebar/SidebarSubitems.vue';

import Row from '@root/src/apps/components/sidebar/Row.vue';

export default {
  name: 'Sidebar',

  mixins: [Mixins, CreativeTraits],

  components: {
    Row,
    SidebarContainer,
    SidebarItem,
    SidebarSubitems,
  },

  data() {
    return {
      creatives: undefined,
      campaign: undefined,
      folder: undefined,

      type_strings: TYPE_STRINGS,
    };
  },

  created() {
    CreativesService.subscribe(state => {
      this.creatives = state?.creatives;
      this.campaign = state?.campaign;
      this.folder = state?.folder;
    }, this);
  },

  methods: {
    splitQuantum(creative) {
      let creatives = [];

      // mobile
      if (creative.settings?.quantum?.mobile != null) {
        for (const size of creative.settings.quantum.mobile) {
          creatives.push(tmp(creative, size, 0, `${size}xM`));
        }
      }

      // desktop
      if (creative.settings?.quantum?.desktop != null || creative.settings?.quantum?.custom != null) {
        for (const size of [...creative.settings.quantum.desktop, ...creative.settings.quantum.custom]) {
          creatives.push(tmp(creative, size, 1, `${size}xD`));
        }
      }

      return creatives;
    },
  },
};
</script>
