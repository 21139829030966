import { render, staticRenderFns } from "./FeedbackIcons.vue?vue&type=template&id=3a2f5cb2&lang=pug"
import script from "./FeedbackIcons.vue?vue&type=script&lang=js"
export * from "./FeedbackIcons.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports