<template lang="pug">
div
  router-link.preview-navbar-to-campaigns(v-if="userHasMobile()" :to="uri")
    i.nexd-icon-32-arrow-right

  Loading(v-if="loading")
  CampaignCreativeNotFound(v-else-if="not_found || !creatives?.length" type="creative")
  div(v-else :class="$route.params.campaign_id != null ? 'creative' : ''")
    template(v-if="!userHasMobile()")
      Feedback(:creative="creative")
      Sidebar(v-if="isList() || isHash() || isQuantum(creative?.type ?? 0) || $route.params.campaign_id != null")
    Preview(v-if="creative" :creative="creative" :key="preview_key" :show_mobile_gyro_toggle="show_mobile_gyro_toggle" :gyro_enabled="gyro_enabled")

  Splash(
    :init="!loading"
    :has_video="creative && isVideo(creative.type)"
    :has_gyro="creative?.has_gyro"
    @syncGyroSensorStatus="syncGyroSensorStatus"
    @removeGyroToggle="removeGyroToggle"
    @changeGyroStatus="changeGyroStatus"
  )
</template>

<script>
import CreativesService from '@services/CreativesService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import Loading from '@master/UI/Loading.vue';

import Feedback from '@root/src/apps/components/sidebar/Feedback.vue';
import Preview from '@root/src/apps/components/preview/Preview.vue';
import Sidebar from '@root/src/apps/components/sidebar/Sidebar.vue';
import Splash from '@root/src/apps/components/global/Splash.vue';

export default {
  name: 'Creative',

  mixins: [CreativeTraits, Mixins],

  components: {
    CampaignCreativeNotFound,
    Feedback,
    Loading,
    Preview,
    Sidebar,
    Splash,
  },

  computed: {
    loading() {
      return this.creatives === undefined && !this.not_found;
    },

    creative() {
      if (this.loading) {
        return null;
      }

      let item_id = this.$route.params.creative_id ?? this.$route.params.live_id;

      if (this.$route.hash) {
        item_id += `_${this.$route.hash.slice(1)}`;
      }

      return CreativesService.get(item_id);
    },

    preview_key() {
      let key = this.creative?.creative_id ?? '';

      if (this.isQuantum(this.creative?.type ?? 0)) {
        key += `_${this.$route.hash}`;
      }

      return key;
    },

    campaign_id() {
      return this.$route.params.campaign_id ?? this.campaign?.campaign_id;
    },

    uri() {
      const creative_ids = this.$route.params.creative_ids;

      if (creative_ids != null) {
        return `/list/${creative_ids}`;
      }

      if (this.campaign_id != null) {
        return `/c/${this.campaign_id}`;
      }

      return '/';
    },
  },

  data() {
    return {
      creatives: undefined,
      campaign: undefined,
      not_found: false,

      show_mobile_gyro_toggle: true,
      gyro_enabled: true,
    };
  },

  created() {
    CreativesService.load();
    CreativesService.subscribe(state => {
      this.creatives = state?.creatives;
      this.campaign = state?.campaign;
      this.not_found = state?.not_found;
    }, this);
  },

  methods: {
    removeGyroToggle() {
      this.show_mobile_gyro_toggle = false;
    },

    toggleGyro() {
      this.gyro_enabled = !this.gyro_enabled;
      this.syncGyroSensorStatus();
    },

    changeGyroStatus(status) {
      this.gyro_enabled = status;
    },

    changeGesture(gesture) {
      if (window.curlify?.gesture?.changeType == null) {
        return;
      }

      try {
        window.curlify.gesture.changeType(gesture ?? 'gesture');
      } catch (err) {
        console.error('Framework error with gesture change');
        console.error(err);
      }
    },

    syncGyroSensorStatus() {
      if (this.gyro_enabled) {
        this.changeGesture(null);
      } else {
        this.changeGesture('gesture2');
      }

      if (window.curlify?.setSensors != null) {
        window.curlify.setSensors(this.gyro_enabled);
      } else if (window.curlify?.sensorsDisabled != null) {
        // legacy way
        window.curlify.sensorsDisabled = this.gyro_enabled;
      }
    },
  },
};
</script>
