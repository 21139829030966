<template lang="pug">
div.border-bottom.pb-16
  div.flex.flex-align-center.flex-justify-between.column-gap-8(v-if="comment.name != null && comment.status == null")
    div.fs-14.overflow-ellipsis {{ comment.name }}
    i.nexd-icon-32-delete.color-red-primary.cursor-pointer(v-if="can_delete" aria-hidden="true" @click="$emit('remove')")
  div.status-button.approve.simple(v-else-if="comment.status === FEEDBACK.APPROVED")
    i.nexd-icon-16-check-mini(aria-hidden="true")
    | Approved
  div.status-button.reject.simple(v-else-if="comment.status === FEEDBACK.REJECTED")
    i.nexd-icon-16-close-small(aria-hidden="true")
    | Rejected
  div.status-button.simple(v-else-if="comment.status === FEEDBACK.PENDING")
    | Status removed
  div.fs-12.color-gray-800(:class="{'mt-4': !can_delete}") {{ comment.created_on | TimeDifferenceFilter }}
  div.mt-8.fs-14(v-if="comment.body != null && comment.status == null") {{ comment.body }}
</template>

<script>
import { FEEDBACK } from '@master/constants';

export default {
  name: 'Comment',

  props: {
    comment: {
      type: Object,
      default: () => null,
    },

    creative: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      FEEDBACK,

      can_delete: false,
    };
  },

  created() {
    this.$user.subscribe(async user => {
      if (user != null) {
        this.can_delete = user.user_id === this.creative?.created_by;
      }
    }, this);
  },
};
</script>
