export function tmp(creative, sizeset, device, quantum) {
  const size = sizeset.split('x');

  return {
    ...creative,
    width: parseInt(size[0], 10),
    height: parseInt(size[1], 10),
    device,
    quantum,
  };
}
