<template lang="pug">
aside#feedback(v-if="can_see_comments" :class="{opened}" @toggle="toggleSidebar")
  div.px-16.flex.flex-direction-column.row-gap-16(style="max-height: calc(100vh - 5rem)")
    div.mt-16.flex.flex-align-center.flex-justify-between
      h4.fs-18 Comments
      i.nexd-icon-32-close.hover-primary.cursor-pointer(aria-hidden="true" @click="toggleSidebar")

    section.flex.flex-align-center.column-gap-8.border-bottom.pb-16
      button.status-button(
        v-for="button of buttons"
        :key="button.value"
        :class="{'active': creative?.feedback_status === button.value, [button.label.toLowerCase()]: true}"
        :disabled="saving_status"
        @click="button.cta"
      )
        i(:class="`nexd-icon-16-${button.icon}`" aria-hidden="true")
        | {{ button.label }}

    section.flex.flex-direction-column.row-gap-16(v-if="items?.length" ref="comments" style="min-width: 0; overflow: auto;")
      Comment(v-for="comment of items" :key="comment.id" :comment="comment" :creative="creative" @remove="removeComment(comment)")

    form.flex.flex-direction-column.row-gap-8(v-if="opened")
      div.flex.flex-align-center.column-gap-8(v-if="new_comment.user.user_id != null")
        UserImage(:name="new_comment.user.name" :img="new_comment.user.img")
        div.fs-14 {{ new_comment.user.name }}
      Input(v-else ref="input" v-model="new_comment.user.name" variant="empty" placeholder="Your name" :disabled="saving")
      Input(ref="textarea" variant="empty" v-model="new_comment.body" placeholder="Add a comment" :textarea="true" :autosize="false" :rows="4" :maxlength="500" :disabled="saving")
      Buttons
        Button(type="primary" label="Add comment" :loading="saving" :disabled="!is_valid" :tooltip="is_valid ? null : {value: tooltip, position: 'left'}" @click="addComment")
</template>

<script>
import { FEEDBACK } from '@master/constants';
import FeedbackService from '@services/FeedbackService';

import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Input from '@master/UI/Input/Input.vue';
import SidebarContainer from '@master/UI/Sidebar/SidebarContainer.vue';
import UserImage from '@master/UI/UserImage/User.vue';

import Comment from '@components/sidebar/Comment.vue';

export default {
  name: 'Feedback',

  components: {
    Buttons,
    Button,
    Comment,
    Input,
    SidebarContainer,
    UserImage,
  },

  props: {
    creative: {
      type: Object,
      default: null,
    },
  },

  computed: {
    is_valid() {
      return this.new_comment.user.name.trim() !== '' && this.new_comment.body.trim() !== '';
    },

    tooltip() {
      if (this.is_valid) return null;

      if (this.new_comment.user.name.trim() === '') {
        return 'Please fill name field.';
      }

      return 'Please fill comment field.';
    },

    buttons() {
      return [
        {
          label: 'Approve',
          value: FEEDBACK.APPROVED,
          icon: 'check-mini',
          cta: this.approve,
        },
        {
          label: 'Reject',
          value: FEEDBACK.REJECTED,
          icon: 'close-small',
          cta: this.reject,
        },
      ];
    },
  },

  data() {
    return {
      can_see_comments: false,
      items: undefined,

      new_comment: {
        user: {
          user_id: null,
          name: '',
          img: null,
        },
        body: '',
      },

      saving: false,
      saving_status: false,
      opened: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user != null) {
        this.can_see_comments = this.$user.canUseCreativeComments();
        this.new_comment.user.user_id = user.user_id;
        this.new_comment.user.name = user.name;
        this.new_comment.user.img = user.img;
      }
    }, this);

    FeedbackService.subscribe(state => {
      this.items = state?.items;
    }, this);

    FeedbackService.state.subscribe(state => {
      this.opened = state?.opened ?? false;
    }, this);
  },

  methods: {
    approve() {
      if (this.creative.feedback_status === FEEDBACK.APPROVED) {
        this.unset();
        return;
      }

      this.updateFeedbackStatus('approve');
    },

    reject() {
      if (this.creative.feedback_status === FEEDBACK.REJECTED) {
        this.unset();
        return;
      }

      this.updateFeedbackStatus('reject');
    },

    unset() {
      if (this.creative.feedback_status === FEEDBACK.PENDING) return;

      this.updateFeedbackStatus('unset');
    },

    updateFeedbackStatus(status) {
      if (status == null || this.saving_status) return;

      this.saving_status = true;

      this.$http
        .post(`creatives/${this.creative.creative_id}/public/feedback/${status}`)
        .then(response => {
          FeedbackService.addItem(response);
          this.$set(this.creative, 'feedback_status', response.status);
        })
        .catch(() => {
          /** supress errors */
        })
        .finally(() => {
          this.saving_status = false;
        });
    },

    async addComment() {
      if (!this.is_valid) return;

      const payload = {
        name: this.new_comment.user.name,
        body: this.new_comment.body,
      };

      this.saving = true;

      this.$http
        .post(`/creatives/${this.creative.creative_id}/public/feedback/comments`, payload)
        .then(response => {
          FeedbackService.addItem(response);

          if (this.creative.feedback_comment_count != null) {
            this.$set(this.creative, 'feedback_comment_count', this.creative.feedback_comment_count + 1);
          } else {
            this.$set(this.creative, 'feedback_comment_count', 1);
          }

          this.new_comment.body = '';

          if (this.new_comment.user.user_id == null) {
            this.new_comment.user.name = '';
          }

          this.$refs.comments?.scrollTo(0, 0);
        })
        .catch(() => {
          /** supress errors */
        })
        .finally(() => {
          this.saving = false;
        });
    },

    async removeComment(comment) {
      if (
        !(await this.$confirm('Are you sure you want to delete this comment?', null, {
          buttons: [
            { type: 'link-primary', label: 'Cancel', action: false },
            { type: 'primary', label: 'Delete', action: true },
          ],
        }))
      ) {
        return;
      }

      FeedbackService.removeItems([comment.item_id]);

      if (this.creative.feedback_comment_count != null) {
        this.$set(this.creative, 'feedback_comment_count', this.creative.feedback_comment_count - 1);
      }

      this.$http.delete(`creative/${this.creative.creative_id}/feedback/comments/${comment.item_id}`).catch(() => {
        /** supress errors */
      });
    },

    toggleSidebar() {
      FeedbackService.state.toggle();
    },
  },

  watch: {
    'creative.creative_id': {
      handler() {
        FeedbackService.load();
      },
      immediate: true,
    },
  },
};
</script>
