<template lang="pug">
div.for-desktop.color-gray-800.fs-14(v-if="show" :class="styles.preview_toggle_state")
  div.flex.flex-align-center.flex-grow
    img.mr-8(:src="`${$cdn}dist/assets/cm/warning-blue.svg`" alt="warning")
    span {{ warning_text }}

  div.flex.flex-align-center.column-gap-8
    span Not Exported
    Toggle(:value="isLiveCreative()" :labels="false" @input="toggleCreativeState")
    span Live Creative
</template>

<script>
import styles from './header.module.scss';

import QRService from '@services/QRService';
import Mixins from '@_helpers/Mixins.vue';

import Toggle from '@master/UI/Toggle.vue';

export default {
  name: 'PreviewToggleState',

  mixins: [Mixins],

  components: {
    Toggle,
  },

  props: {
    creative: {
      type: Object,
      required: true,
    },
  },

  computed: {
    show() {
      return this.can_edit && this.is_live;
    },

    warning_text() {
      const reminder_text = 'Don’t forget to click the Update Live button, or download new tags if needed, to push any changes live.';
      if (this.isLiveCreative()) {
        return `This preview shows live creative. ${reminder_text}`;
      }

      return `This preview shows currently unpublished creative. ${reminder_text}`;
    },
  },

  data() {
    return {
      styles,
      can_edit: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.can_edit = this.$user.isNexdAdmin() || user.user_id === this.creative.created_by;
      }
    }, this);
  },

  methods: {
    toggleCreativeState() {
      const hash = this.$route.hash != null ? this.$route.hash : null;

      let params = { state: 'live', creative_id: this.creative.live_id };

      if (this.isLiveCreative()) {
        params = { state: 'creative', creative_id: this.creative.creative_id };
      }

      this.$router.push({ params, hash }).then(() => {
        QRService.get();
      });
    },
  },
};
</script>
