import CreativesService from '@services/CreativesService';
import FeedbackService from '@services/FeedbackService';
import QRService from '@services/QRService';

export default {
  install: (Vue, router) => {
    CreativesService.init(Vue, router);
    FeedbackService.init(Vue, router);
    QRService.init(Vue, router);
  },
};
