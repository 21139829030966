<template lang="pug">
div
  center.landing

    div.landing-top.for-desktop
      h1.mb-16 {{ $whitelabel.greeting_texts.desktop.top.title[0] || '' }}
        br
        span {{ replaceMacros($whitelabel.greeting_texts.desktop.top.title[1] || '') }}
      span.color-gray-800 {{ replaceMacros($whitelabel.greeting_texts.desktop.top.subtitle) }}

    div.landing-bottom.py-32

      div.for-mobile.landing-mobile
        div#logo
        div#coop-logo(v-if="$whitelabel.logo.coop != null")
        img(alt="Hello" v-whitelabel-image="{color: $whitelabel?.color?.splash_text, path: 'dist/assets/login/wave.svg'}")
        h2 {{ $whitelabel.greeting_texts.mobile.title }}
        p.px-32(v-if="$whitelabel.demos?.length > 0") {{ $whitelabel.greeting_texts.mobile.subtitle }}

      template(v-if="$whitelabel.demos?.length > 0")
        h2.for-desktop.fw-100.fs-32 {{ $whitelabel.greeting_texts.desktop.bottom.title }}
        div.creative-cards
          router-link(v-for="campaign in $whitelabel.demos" :key="campaign.campaign_id" :to="{ path: `c/${campaign.campaign_id}` }")
            img.for-desktop(:src="`${$cdn}${campaign.icon}`" :alt="campaign.title" :style="{width: '200px'}")
            p.fw-500 {{ campaign.title }}

      template(v-if="!$whitelabel.is_whitelabel")
        h2.for-desktop.fw-100.fs-32 Just looking for some inspiration?
        div.for-desktop.mt-16.flex.flex-justify-center.flex-wrap.column-gap-24
          a.external-link.flex.flex-align-center.column-gap-8(v-for="link of external_links" :key="link.href" :href="link.href" target="_blank")
            img(v-whitelabel-image="link.image" :alt="link.label")
            span.fw-300 {{ link.label }}
  footer
</template>

<script>
export default {
  name: 'Landing',

  data() {
    return {
      // desktop & nexd only
      external_links: [
        {
          label: 'View all layouts and specs',
          href: 'https://www.nexd.com/layouts/',
          image: 'dist/assets/preview/link-icons/illustration-specs.svg',
        },
        {
          label: 'Take a look at our demo gallery',
          href: 'https://www.nexd.com/demo-gallery/',
          image: 'dist/assets/preview/link-icons/examples-illustration.svg',
        },
      ],
    };
  },

  methods: {
    replaceMacros(text) {
      return text.replace(/%\{TITLE\}%/i, this.$whitelabel.title);
    },
  },
};
</script>
