<template lang="pug">
div.flex.flex-align-center.column-gap-4
  i.nexd-icon-32-qr.hover-primary.cursor-pointer(
    aria-hidden="true"
    :class="{[active]: show_qr_code}"
    v-tooltip="{value: 'QR Code'}"
    @click="toggleQRCode"
  )
  i.nexd-icon-32-tag.hover-primary.cursor-pointer(
    v-if="can_access_analytics" aria-hidden="true"
    v-tooltip="{value: 'Download tag'}"
    @click="downloadTag"
  )

  a(v-if="can_access_analytics" :href="analytics_link" target="_blank" v-tooltip="{value: 'Analytics'}")
    i.nexd-icon-32-analytics(aria-hidden="true")

  a(v-if="can_edit" :href="edit_creative_link" target="_blank" v-tooltip="{value: 'Edit'}")
    i.nexd-icon-32-edit(aria-hidden="true")

  i.nexd-icon-32-share.hover-primary.cursor-pointer(
    aria-hidden="true"
    v-tooltip="{value: 'Copy link'}"
    @click="copy(share_url)"
  )
  i.nexd-icon-32-comment.hover-primary.cursor-pointer(
    v-if="can_see_comments"
    aria-hidden="true"
    :class="{[active]: show_comments}"
    v-tooltip="{value: 'Comments'}"
    @click="openComments"
  )
</template>

<script>
import DSPService from '@master/Services/Cache/DSPService';
import FeedbackService from '@services/FeedbackService';
import QRService from '@services/QRService';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

export default {
  name: 'PreviewLinks',

  mixins: [CreativeTraits, Mixins],

  props: {
    creative: {
      type: Object,
      required: true,
    },
  },

  computed: {
    is_google_ads() {
      return DSPService.hasZip(this.creative?.dsp);
    },

    can_access_analytics() {
      return this.can_edit && this.is_live && !this.is_google_ads;
    },

    analytics_link() {
      return `${this.$cm}analytics/c/${this.creative.campaign_id}/creative/${this.creative.live_id}`;
    },

    edit_creative_link() {
      if (this.isDevtoolCreative()) {
        return `${this.$developer}creative/${this.creative.creative_id}`;
      }

      if (this.creative?.folder_id != null) {
        return `${this.$cm}f/${this.creative.folder_id}/${this.creative.creative_id}`;
      }

      return `${this.$cm}c/${this.creative.campaign_id}/${this.creative.creative_id}`;
    },
  },

  data() {
    return {
      can_edit: false,
      can_see_comments: false,

      share_url: `${window.location.protocol}//${window.location.host}/creative/${this.creative.creative_id}`,
      show_qr_code: false,
      show_comments: false,

      active: 'bg-light border-radius',
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.can_edit = this.$user.isNexdAdmin() || user.user_id === this.creative.created_by;
        this.can_see_comments = this.$user.canUseCreativeComments();
      }
    }, this);

    QRService.subscribe(state => {
      this.show_qr_code = state.show;
    }, this);

    FeedbackService.state.subscribe(state => {
      this.show_comments = state?.opened ?? false;
    }, this);
  },

  methods: {
    downloadTag() {
      this.$http.get(`campaigns/${this.creative.campaign_id}/creatives/tag/${this.creative.creative_id}`, { notification: false });
    },

    openComments() {
      FeedbackService.state.toggle();
    },

    toggleQRCode() {
      QRService.toggle();
    },
  },
};
</script>
