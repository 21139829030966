import Subscriber from '@libs/Subscriber';
import CreativesCache from '@_libs/CreativesCache';

class CreativesService extends Subscriber {
  #cache = new CreativesCache();

  async load() {
    if (this.#hasData()) {
      return;
    }

    const path = this.#getPath();

    if (path == null) {
      this.data = {
        not_found: true,
      };
      return;
    }

    return this.$http
      .get(path)
      .then(response => {
        this.data = response;
        this.#cache.store(response?.creatives);
      })
      .catch(() => {
        this.data = {
          not_found: true,
        };
      });
  }

  get(creative_id) {
    if (creative_id == null && this.data?.creatives?.length > 0) {
      return this.data?.creatives?.[0];
    }

    return this.#cache.get(creative_id);
  }

  #getPath() {
    const { campaign_id, folder_id, creative_ids, creative_id, hash } = this.#getParams();
    const path = 'public/preview';

    if (campaign_id != null) {
      if (folder_id != null) {
        return `${path}/campaign/${campaign_id}/folder/${folder_id}`;
      }

      return `${path}/campaign/${campaign_id}`;
    }

    if (hash != null) {
      return `${path}/list/${hash}`;
    }

    // @deprecated by SD-7644 / shortener URL / hash views
    if (creative_ids != null) {
      return `${path}/creatives/${creative_ids}`;
    }

    if (creative_id != null) {
      return `${path}/creatives/${creative_id}`;
    }
  }

  #getParams() {
    return this.router?.history.current.params;
  }

  #hasData() {
    if (this.data?.campaign == null) {
      return false;
    }

    return this.data.campaign.campaign_id === this.#getParams()?.campaign_id;
  }
}

export default new CreativesService();
