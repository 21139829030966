<template lang="pug">
div
  Loading(v-if="loading")
  CampaignCreativeNotFound(v-else-if="not_found || !creatives?.length")
  div.p-24(v-else)
    div.mb-16.flex.flex-justify-between
      div
        h1 {{ campaign.name }}
        h3.color-gray-800(v-if="folder != null") {{ folder.name }}
      i.nexd-icon-32-share.color-primary(aria-hidden="true" @click="copyToClipboard")

    ListItem.border-bottom(
      v-if="creatives"
      v-for="(creative, index) of creatives"
      :key="creative.creative_id"
      :creative="creative"
      :class="{'border-top': index === 0}"
    )
</template>

<script>
import { VIEW } from '@master/constants';
import CreativesService from '@services/CreativesService';

import Mixins from '@_helpers/Mixins.vue';

import CampaignCreativeNotFound from '@master/UI/CampaignCreativeNotFound.vue';
import Loading from '@master/UI/Loading.vue';

import ListItem from '@root/src/apps/components/global/ListItem.vue';

export default {
  name: 'Campaign',

  mixins: [Mixins],

  components: {
    CampaignCreativeNotFound,
    ListItem,
    Loading,
  },

  computed: {
    loading() {
      return this.creatives === undefined && !this.not_found;
    },
  },

  data() {
    return {
      creatives: undefined,
      campaign: undefined,
      folder: undefined,
      not_found: false,
    };
  },

  created() {
    CreativesService.load();
    CreativesService.subscribe(state => {
      this.creatives = state?.creatives;
      this.campaign = state?.campaign;
      this.folder = state?.folder;
      this.not_found = state?.not_found;

      if (!this.userHasMobile()) {
        this.redirectToCreatives();
      }
    }, this);
  },

  methods: {
    redirectToCreatives() {
      if (!this.creatives?.length) {
        return;
      }

      this.$router.push({
        name: this.getCreativesRouteName(),
        params: {
          ...this.$route.params,
          state: 'creative',
          creative_id: this.creatives[0].creative_id,
        },
      });
    },

    getCreativesRouteName() {
      switch (this.$route.name) {
        case VIEW.PREVIEW.FOLDER:
          return VIEW.PREVIEW.CREATIVES_FOLDER;
        case VIEW.PREVIEW.LIST:
          // @deprecated by SD-7644 / shortener URL / hash views
          return VIEW.PREVIEW.CREATIVES_LIST;
        case VIEW.PREVIEW.HASH_LIST:
          return VIEW.PREVIEW.HASH_CREATIVES_LIST;
        case VIEW.PREVIEW.CAMPAIGN:
        default:
          return VIEW.PREVIEW.CREATIVES_CAMPAIGN;
      }
    },

    copyToClipboard() {
      // copy current url to clipboard
      // remove any query params that might be added
      // used to copy location of folder, campaign and list view
      this.copy(window.location.href.split('?').shift(), 'Link is copied to clipboard');
    },
  },
};
</script>
