<template lang="pug">
SidebarItem(
  v-if="is_quantum_size"
  :label="`${ad_size} • ${['mobile', 'desktop'][creative.device]}`"
  :quantum_child="true"
  :active="is_selected"
  @open="selectCreative"
)
div.sidebar-item(v-else :class="{'sidebar-item__active': is_selected}" @click="selectCreative")
  CreativeThumbnail(:asset="{thumbnail_url: creative.splash}")
  div.flex-grow(style="min-width: 0;")
    h4.overflow-ellipsis(v-if="!is_quantum_size") {{ creative.name }}
  FeedbackIcons.ml-8(:creative="creative")
</template>

<script>
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

import CreativeThumbnail from '@master/UI/Thumbnail/CreativeThumbnail.vue';
import SidebarItem from '@master/UI/Sidebar/SidebarItem.vue';

import FeedbackIcons from '@components/sidebar/FeedbackIcons.vue';

export default {
  name: 'Row',
  mixins: [CreativeTraits, Mixins],

  components: {
    CreativeThumbnail,
    FeedbackIcons,
    SidebarItem,
  },

  props: {
    creative: Object,
    type: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ad_size() {
      if (this.isFullscreen()) return 'Fullscreen';
      if (this.isQuantum() && !this.is_quantum_size) return 'Multiple';
      return `${this.creative.width}x${this.creative.height}`;
    },

    is_selected() {
      if (!this.is_quantum_size) return this.$route.params.creative_id === this.creative.creative_id || this.$route.params.creative_id === this.creative.live_id;

      const splitted_hash = this.$route.hash.slice(1).split('x');

      let device = 0;
      if (splitted_hash[2] === 'D') {
        device = 1;
      }

      const active_id = this.isLiveCreative() ? this.creative.live_id : this.creative.creative_id;
      const quantum_size = `${this.creative.width}x${this.creative.height}`;
      const quantum_size_from_hash = `${splitted_hash[0]}x${splitted_hash[1]}`;

      return quantum_size === quantum_size_from_hash && this.creative.device === device && active_id === this.$route.params.creative_id;
    },
  },

  methods: {
    selectCreative() {
      const has_live_id = this.creative.live_id != null;
      const can_select_live_creative = this.isLiveCreative() && has_live_id;

      this.$router
        .push({
          params: {
            state: can_select_live_creative ? 'live' : 'creative',
            creative_id: can_select_live_creative ? this.creative.live_id : this.creative.creative_id,
          },
          hash: this.creative.quantum != null ? `#${this.creative.quantum}` : '',
        })
        .catch(() => {
          /** supress duplicate route error */
        });
    },
  },
};
</script>
