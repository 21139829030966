class RequestLimiter {
  #hooks = new Map();

  /**
   * @param {string} key
   * @param {Function} cb
   * @param {number} [timeout]
   */
  hook(key, cb, timeout = 1500) {
    if (key == null) {
      throw new Error('RequestLimiter can not be used without key');
    }

    this.#unhook(key);

    if (typeof cb === 'function') {
      this.#hooks.set(
        key,
        setTimeout(() => {
          cb();
          this.#unhook(key);
        }, timeout),
      );
    }
  }

  #unhook(key) {
    if (this.#hooks.has(key)) {
      clearTimeout(this.#hooks.get(key));
      this.#hooks.delete(key);
    }
  }
}

export default new RequestLimiter();
