const IconLoader = {
  url(path = null) {
    if (path == null) {
      console.error('Icon file path has to be specified');
      return;
    }

    return `${process.env.VUE_APP_CDN}dist/assets/${path}`;
  },

  source() {
    return `${process.env.VUE_APP_CDN}dist/assets`;
  },
};

export default IconLoader;
