<template lang="pug">
div.mb-16(:class="styles.header")
  div.flex.flex-align-start.flex-justify-between.column-gap-16
    h1.lh-32 {{ creative.name }}
    PreviewLinks(v-bind="$props")
  PreviewDetails(v-bind="$props")
  //- Options (device, har etc. + pack sizes)
  slot
  PreviewToggleState(v-bind="$props")
</template>

<script>
import styles from '@components/preview/header/header.module.scss';

import Mixins from '@_helpers/Mixins.vue';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import PreviewDetails from '@components/preview/header/PreviewDetails.vue';
import PreviewLinks from '@components/preview/header/PreviewLinks.vue';
import PreviewToggleState from '@components/preview/header/PreviewToggleState.vue';

import Toggle from '@master/UI/Toggle.vue';

export default {
  name: 'PreviewHeader',

  mixins: [Mixins, CreativeTraits],

  components: {
    PreviewDetails,
    PreviewLinks,
    PreviewToggleState,
    Toggle,
  },

  props: {
    creative: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
