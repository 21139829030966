<template lang="pug">
div.splash(:style="splash_style" @wheel.prevent @touchmove.prevent @scroll.prevent v-if="userHasMobile()")
  div#logo(:style="logo_style")
  div#coop-logo(v-if="$whitelabel.logo.coop != null" :style="coop_logo_style")
  div(:style="info_style")
    img(alt="Hello" v-whitelabel-image="{color: $whitelabel?.color?.splash_text, path: 'dist/assets/login/wave.svg'}")
    h2 Welcome
    div(v-if="has_video")
      div.fs-14.mb-4 Just tap the button
      div.fs-14 to access the awesomeness
      div.mt-32.splash-btn(@click="showAd") See The Ad
    div(v-else-if="has_gyro && ask_permission")
      div.fs-14.mb-4 To fully experience the ad,
      div.fs-14 please enable your phone's motion sensor
      div.mt-32.splash-btn(@click="askMotionPermission") Allow access
      div.mt-16.splash-btn.splash-btn-deny(@click="deniedGyroAccess") No thanks
    div(v-else)
      div.fs-14.mb-4 Hold on a second,
      div.fs-14 we are loading...
</template>

<script>
import { setCookie, getCookie } from '@helpers/Global';
import Mixins from '@_helpers/Mixins.vue';

export default {
  name: 'Splash',

  mixins: [Mixins],

  props: {
    init: {
      type: Boolean,
      default: false,
    },

    has_gyro: {
      type: Boolean,
      default: false,
    },

    has_video: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    info_style() {
      let style = {};
      if (!this.loading) style.animationName = 'fade_out';
      if (this.hide_text) style.animationName = 'close_out';
      if (this.text_hidden) style.display = 'none';
      return style;
    },

    splash_style() {
      let style = {};
      if (this.text_hidden) style.animationName = 'minimize_splash';
      return style;
    },

    logo_style() {
      let style = {};
      if (this.text_hidden) style.animationName = 'minimize_logo';
      return style;
    },

    coop_logo_style() {
      let style = {};
      if (this.text_hidden) style.display = 'none';
      return style;
    },
  },

  data() {
    return {
      loading: true,
      hide_text: false,
      text_hidden: false,
      timeout: 800,
      ask_permission: false,
    };
  },

  created() {
    if (this.init && !this.has_video && !this.has_gyro) {
      this.showAd();
    } else if (this.has_gyro) {
      this.checkMotionPermission();
    }
  },

  methods: {
    showAd() {
      setTimeout(() => {
        this.loading = false;
      }, this.timeout);
    },

    checkMotionPermission() {
      if (window.navigator?.permissions?.query != null) {
        window.navigator.permissions
          .query({ name: 'gyroscope' })
          .then(result => {
            if (result.state === 'granted') {
              this.$emit('changeGyroStatus', false);
              this.$emit('syncGyroSensorStatus');
              this.showAd();
            } else if (result.state === 'prompt') {
              this.ask_permission = true;
            } else {
              this.$emit('removeGyroToggle');
              this.showAd();
            }
          })
          .catch(() => {
            this.$emit('removeGyroToggle');
            this.showAd();
          });
      } else if (window.DeviceMotionEvent?.requestPermission != null) {
        // on iOS with motionevent, show the button to call @askMotionPermission
        const hide_toggle_btn = getCookie('NXDGRYSKP');
        if (hide_toggle_btn === 'yes') {
          this.$emit('removeGyroToggle');
          this.showAd();
        } else {
          this.ask_permission = true;
        }
      } else {
        this.$emit('removeGyroToggle');
        this.showAd();
      }
    },

    askMotionPermission() {
      if (!this.ask_permission) return;
      if (window.navigator?.permissions?.request != null) {
        window.navigator.permissions
          .request({ name: 'gyroscope' })
          .then(() => {
            this.$emit('changeGyroStatus', true);
            this.$emit('syncGyroSensorStatus');
            this.showAd();
          })
          .catch(() => {
            this.$emit('removeGyroToggle');
            this.showAd();
          });
      } else if (window.DeviceMotionEvent?.requestPermission != null) {
        window.DeviceMotionEvent.requestPermission()
          .then(state => {
            if (state === 'granted') {
              this.$emit('changeGyroStatus', true);
              this.$emit('syncGyroSensorStatus');
            } else {
              this.skipMotionPermission();
            }
            this.showAd();
          })
          .catch(() => {
            this.$emit('removeGyroToggle');
            this.showAd();
          });
      } else {
        this.$emit('removeGyroToggle');
        this.showAd();
      }
    },

    skipMotionPermission() {
      if (window.navigator?.permissions?.query != null) {
        // probbaly android
      } else if (window.DeviceMotionEvent?.requestPermission != null) {
        // probably ios device, set 30 day cookie that is being checked wherer to show the gyro panel or not
        setCookie('NXDGRYSKP', 'yes', 30);
      }
      this.$emit('removeGyroToggle');
      this.showAdd();
    },

    deniedGyroAccess() {
      this.$emit('removeGyroToggle');
      this.$emit('changeGyroStatus', false);
      this.showAd();
    },
  },

  watch: {
    info_style() {
      setTimeout(() => {
        this.hide_text = true;
      }, this.timeout);

      setTimeout(() => {
        this.text_hidden = true;
      }, this.timeout * 2);
    },

    init() {
      if (this.init && !this.has_video && !this.has_gyro) {
        this.showAd();
      }
    },

    has_gyro() {
      if (this.has_gyro) {
        this.checkMotionPermission();
      }
    },
  },
};
</script>
