import { confirm } from '@libs/alerts';
import { appendScript } from '@helpers/Global';

export default {
  started: false,
  customCallback: null,

  validate() {
    // route change triggers start, make sure not to trigger it twice
    // non auth routes have no adblock checks, but if login changes route this should be runnned once
    if (this.started === false) {
      this.started = true;
      this.start();
    }
  },

  showNotification() {
    if (
      this.customCallback != null &&
      typeof this.customCallback === 'function'
    ) {
      this.customCallback();
      setTimeout(() => {
        this.start();
      }, 8000);
    } else {
      confirm(
        undefined,
        'Please disable your adblocker or add an exception to ' +
          window.location.protocol +
          '//' +
          window.location.hostname +
          ' and reload the page',
      ).then(res => {
        if (res) {
          setTimeout(() => {
            this.start();
          }, 5000);
        } else {
          this.start();
        }
      });
    }
  },

  start() {
    const URL = window.__nexd.cdn + 'dist/adb.js';
    const script = appendScript(
      URL,
      window.__nexd.cdn_version ?? window.__nexd.version,
    );

    // adb.js will try and call this function
    window.__nexd.adblockcallback = blocked => {
      if (blocked) {
        this.showNotification();
      }
      script.parentNode.removeChild(script);
    };

    // to be safe if something is broken with the adb.js
    script.onerror = () => {
      this.showNotification();
      script.parentNode.removeChild(script);
    };
    script.onabort = () => {
      this.showNotification();
      script.parentNode.removeChild(script);
    };
  },
};
