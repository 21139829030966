import Subscriber from '@libs/Subscriber';

export default class ActiveState extends Subscriber {
  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);
  }

  set(data) {
    this.data = data;
  }

  has() {
    return this.data != null;
  }

  get() {
    return this.data;
  }

  is(data) {
    return data && this.has() && data.item_id === this.get().item_id;
  }
}
