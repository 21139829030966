import { VIEW } from '@master/constants';

import Landing from '@root/src/apps/views/Landing.vue';
import Creative from '@root/src/apps/views/Creative.vue';
import Campaign from '@root/src/apps/views/Campaign.vue';
import ClickOutPage from '@master/UI/ClickOutPage/ClickOutPage';

const default_meta = { app_name: 'Preview', app_key: 'preview' };

export default [
  {
    path: '/',
    name: VIEW.PREVIEW.LANDING,
    component: Landing,
    meta: { ...default_meta },
  },
  {
    path: '/c/:campaign_id',
    name: VIEW.PREVIEW.CAMPAIGN,
    component: Campaign,
    meta: { ...default_meta },
  },
  {
    path: '/c/:campaign_id/f/:folder_id',
    name: VIEW.PREVIEW.FOLDER,
    component: Campaign,
    meta: { ...default_meta },
  },
  {
    path: '/c/:campaign_id/:state/:creative_id',
    name: VIEW.PREVIEW.CREATIVES_CAMPAIGN,
    component: Creative,
    meta: { ...default_meta },
  },
  {
    path: '/c/:campaign_id/f/:folder_id/:state/:creative_id',
    name: VIEW.PREVIEW.CREATIVES_FOLDER,
    component: Creative,
    meta: { ...default_meta },
  },
  {
    path: '/l/:hash',
    name: VIEW.PREVIEW.HASH_LIST,
    component: Campaign,
    meta: { ...default_meta },
  },
  {
    path: '/l/:hash/:state/:creative_id',
    name: VIEW.PREVIEW.HASH_CREATIVES_LIST,
    component: Creative,
    meta: { ...default_meta },
  },
  // @deprecated by SD-7644 / shortener URL / hash views
  {
    path: '/list/:creative_ids',
    name: VIEW.PREVIEW.LIST,
    component: Campaign,
    meta: { ...default_meta },
  },
  // @deprecated by SD-7644 / shortener URL / hash views
  {
    path: '/list/:creative_ids/:state/:creative_id',
    name: VIEW.PREVIEW.CREATIVES_LIST,
    component: Creative,
    meta: { ...default_meta },
  },
  {
    path: '/:state/:creative_id',
    name: VIEW.PREVIEW.CREATIVE,
    component: Creative,
    meta: { ...default_meta },
  },
  {
    path: '/CLICKURL',
    name: VIEW.PREVIEW.CLICKURL,
    component: ClickOutPage,
    meta: { ...default_meta },
  },
  {
    path: '*',
    redirect: { name: VIEW.PREVIEW.LANDING },
  },
];
