import Subscriber from '@libs/Subscriber';
import QueryOptions from '@libs/QueryOptions';
import ActiveState from '@libs/ActiveState';

import FolderService from '@master/Services/FolderService';

import { addFiltersToPath } from '@helpers/Global';

export default class ContentService extends Subscriber {
  #path;

  active = new ActiveState();
  options = new QueryOptions();

  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);
  }

  load(path, cache = false, query_options) {
    this.reset();
    if (query_options) {
      this.options.add(query_options);
    }
    return this.#load(path, cache);
  }

  loadMore(path, cache = false) {
    this.options.update();
    return this.#load(path, cache);
  }

  updateSorting(sort, path, reverse) {
    this.options.updateSorting(sort, reverse);
    this.data = null;
    return this.#load(path);
  }

  updateSearch(search, path) {
    this.options.updateSearch(search);
    this.data = null;
    return this.#load(path);
  }

  reset() {
    this.options.reset();
    this.data = null;
  }

  updateBreadcrumb(id = null, label = null) {
    if (id == null || label == null || this.data?.breadcrumbs == null) return;

    for (const item of this.data.breadcrumbs) {
      if (item.item_id === id) {
        item.label = label;
        break;
      }
    }

    super.sendAll();
  }

  addItem(item) {
    if (this.data == null) {
      this.data = {};
    }
    if (this.data?.items == null) {
      this.data.items = [];
    }

    this.data.items.unshift(item);

    this.#checkFolderActions();
    this.#removeNotFound();
    this.#updateCache();
    super.sendAll();
  }

  addItems(items) {
    if (this.data == null) {
      this.data = {};
    }
    if (this.data?.items == null || this.data?.key?.length === 0) {
      this.data.items = items;
    } else {
      this.data.items.unshift(...items);
    }

    this.#checkFolderActions();
    this.#removeNotFound();
    this.#updateCache();
    super.sendAll();
  }

  removeItems(ids) {
    if (!Array.isArray(ids) || this.data?.items == null) return;

    for (const id of ids) {
      const item = this.data.items?.find(i => i.item_id === id);

      if (!item) continue;

      this.data.items.splice(this.data.items.indexOf(item), 1);
    }

    this.active.set(null);
    FolderService.active.set(null);

    this.#checkFolderActions();
    this.#checkCurrentLocation(ids);
    this.#updateCache();
    super.sendAll();
  }

  updateItems(updated_items) {
    if (!Array.isArray(updated_items) || this.data?.items == null) return;

    for (const updated_item of updated_items) {
      const item = this.data.items?.find(i => i.item_id === updated_item.item_id);

      if (!item) continue;

      for (const key in updated_item) {
        item[key] = updated_item[key];
      }

      if (this.active.is(item)) {
        this.active.set(item);
      }

      if (FolderService.active.is(item)) {
        FolderService.active.set(item);
      }
    }

    this.#updateCache();
    super.sendAll();
  }

  updateCreatedItem(dummy_item, new_item) {
    if (dummy_item == null || new_item == null || this.data?.items == null) {
      return;
    }

    for (const item of this.data.items) {
      if (item.item_id !== dummy_item.item_id) continue;

      for (const key in new_item) {
        item[key] = new_item[key];
      }

      break;
    }

    this.#updateCache();
    super.sendAll();
  }

  updateCustomData(key, value) {
    if (this.data != null && key in this.data) {
      this.data[key] = value;
      super.sendAll();
    }
  }

  get(key) {
    if (this.data?.[key] != null) {
      return this.data[key];
    }

    return null;
  }

  getId(key) {
    return this.router?.history?.current?.params?.[key] ?? null;
  }

  #load(path, cache = false) {
    if (this.data?.can_load_more === false) {
      return null;
    }

    this.#path = addFiltersToPath(path, this.options.get(), false);

    return super.load(this.#path, { keys: ['items'], notification: false }, cache).catch(_ => {
      this.data = {
        not_found: true,
      };
    });
  }

  #removeNotFound() {
    if (this.data?.not_found === true) {
      delete this.data.not_found;
    }
  }

  #checkFolderActions() {
    if (!FolderService.current.has() || this.data.items == null) return;

    const items_length = this.data.items?.length ?? 0;
    const folder = FolderService.current.get();

    folder.actions = {
      ...(folder.actions ?? {}),
      can_remove: items_length === 0,
    };
  }

  #checkCurrentLocation(removed_ids) {
    if (!removed_ids?.length || this.data.breadcrumbs?.length < 2) {
      return;
    }

    if (FolderService.current.has() && FolderService.current.get().item_id === removed_ids[0]) {
      const previous = this.data.breadcrumbs[this.data.breadcrumbs.length - 2];

      if (previous.url != null) {
        this.router.push(previous.url);
      }
    }
  }

  #updateCache() {
    super.updateCache(this.#path);
  }
}
