<template lang="pug">
div.flex.flex-align-center.flex-justify-between.flex-wrap.column-gap-16.row-gap-8(:class="styles.preview_options")
  div.flex.flex-align-center.column-gap-12
    template(
      v-if="(!isQuantum() && !isVast() && !isVpaid() || isQuantum() && $route.hash)"
      v-for="(setting, index) in settings"
    )
      template(v-if="setting.show != null ? setting.show : true")
        div.flex.flex-align-center(:key="index")
          span.mr-4.fs-14.color-gray-800 {{ setting.label }}:
          i(
            v-for="(option, idx) of setting.options"
            v-if="option.show != null ? option.show : true"
            :key="`${index}_${idx}`"
            :class="[option.icon, 'cursor-pointer', {[active]: option.active != null ? option.active : options[setting.key ?? option.key] === option.value}]"
            v-tooltip="{value: option.label ? option.label : null}"
            @click="$emit('change', setting.key ?? option.key, option.value)"
          )
        div(:class="styles.separator")

    span.flex.flex-align-center.color-primary.cursor-pointer(@click="$emit('reload')")
      i.nexd-icon-32-reload(aria-hidden="true")
      span.fs-14 Reload Ad

  div.flex.flex-align-center.column-gap-16.fs-14
    div.lh-32.flex.flex-align-center.column-gap-8(v-for="(detail, index) of details" :key="index" v-tooltip="{value: detail.tooltip}")
      span {{ detail.label }}
      span.color-primary {{ detail.value }}
</template>

<script>
import styles from './header.module.scss';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import Mixins from '@_helpers/Mixins.vue';

export default {
  name: 'PreviewOptions',

  mixins: [CreativeTraits, Mixins],

  props: {
    creative: {
      type: Object,
      required: true,
    },

    options: {
      type: Object,
      required: true,
    },
  },

  computed: {
    settings() {
      return [
        {
          // in prod there was quantum allowed as well but it was buggy
          show: this.isInfeed() || this.isLightbox() || this.isHTML5(),
          label: 'Device',
          key: 'toggle_device',
          options: [
            {
              label: 'Mobile device',
              icon: 'nexd-icon-32-mobile-big',
              value: 0,
            },
            {
              label: 'Desktop device',
              icon: 'nexd-icon-32-desktop-big',
              value: 1,
            },
          ],
        },
        {
          show: this.isMobile(this.options.toggle_device),
          label: 'Device size',
          key: 'toggle_high_aspect_ratio',
          options: [
            {
              label: 'High-aspect ratio device',
              icon: 'nexd-icon-32-mobile-L',
              value: true,
            },
            {
              label: 'Low-aspect ratio device',
              icon: 'nexd-icon-32-mobile-S',
              value: false,
            },
          ],
        },
        {
          label: 'Preview settings',
          options: [
            {
              show: this.isMobile(this.options.toggle_device),
              label: 'Show browser bars ',
              icon: 'nexd-icon-32-browser-bars',
              value: !this.options.toggle_browser_bars,
              active: this.options.toggle_browser_bars,
              key: 'toggle_browser_bars',
            },
            {
              label: 'Show ad in safeframe',
              icon: 'nexd-icon-32-safe-frame',
              value: !this.options.toggle_safeframe,
              active: this.options.toggle_safeframe,
              key: 'toggle_safeframe',
            },
          ],
        },
      ];
    },
  },

  data() {
    return {
      styles,

      details: [
        {
          label: 'Inital load size:',
          value: this.creative?.packsize?.formatted ?? 0,
          tooltip: 'The size of files to complete the first display of the ad (usually splash).',
        },
        {
          label: 'Total load size:',
          value: this.creative?.packsize?.total?.formatted ?? 0,
          tooltip: 'Initial load size plus the size of all asset files, including videos and images that load after interaction.',
        },
      ],

      active: 'bg-light border-radius',
    };
  },
};
</script>
