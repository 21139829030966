<template lang="pug">
div.fs-14(:class="styles.preview_details")
  div.flex.flex-align-center.column-gap-4(v-for="(value, key) in details" :key="key")
    p.color-gray-800 {{ key }}:
    p.fw-500(v-tooltip="{value: value?.length > 15 ? value : null}") {{ value }}
</template>

<script>
import styles from './header.module.scss';

import UsersService from '@master/Services/Cache/UsersService';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

export default {
  name: 'PreviewDetails',

  mixins: [CreativeTraits],

  props: {
    creative: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ad_size() {
      if (this.$route.hash) {
        const quantum = this.$route.hash.slice(1).split('x');
        return `${quantum[0]}x${quantum[1]}`;
      }

      if (this.isFullscreen()) {
        return 'Fullscreen';
      }

      if (this.isQuantum()) {
        return 'Multiple';
      }

      return `${this.creative.width}x${this.creative.height}`;
    },

    details() {
      const details = {
        'Ad type': this.creative.type_string,
        'Ad size': this.ad_size,
        Layout: this.creative.layout_name,
      };

      if (this.admin_toggle) {
        details['Creator'] = this.creator_details.name;
        details['Company'] = this.creator_details.company;
      }

      return details;
    },
  },

  data() {
    return {
      styles,

      admin_toggle: false,
      creator_details: {},
    };
  },

  created() {
    this.$user.subscribe(async user => {
      if (user) {
        this.admin_toggle = this.$user.adminMode();

        if (this.admin_toggle) {
          const creator = await UsersService.get(this.creative?.created_by);

          if (creator != null) {
            this.creator_details = {
              name: creator.name,
              company: creator.group.name,
            };
          }
        }
      }
    }, this);
  },
};
</script>
