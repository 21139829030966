<script>
import { setClipboard } from '@helpers/Global';

export default {
  computed: {
    is_quantum_size() {
      return this.creative?.quantum != null;
    },

    is_live() {
      return this.creative?.live_id != null;
    },
  },

  methods: {
    isLiveCreative() {
      return this.$route.params.state === 'live';
    },

    // @deprecated by SD-7644 / shortener URL / hash views
    isList() {
      return this.$route.params.creative_ids != null;
    },

    isHash() {
      return this.$route.params.hash != null;
    },

    userHasMobile() {
      return /.*(android|blackberry|ipad|iphone|ipod|iemobile|opera mobile|palmos|webos|googlebot-mobile|mobile).*/i.test(window.navigator.userAgent);
    },

    copy(url = null, message = "Creative's link is copied to clipboard") {
      if (url == null) return;

      setClipboard(url)
        .then(_ => {
          this.$notifications.add('primary', message);
        })
        .catch(error => {
          this.$notifications.add('warning', error);
        });
    },
  },
};
</script>
