import { tmp } from '@_helpers';

export default class CreativesCache {
  #cache;

  constructor() {
    this.#cache = new Map();
  }

  get(creative_id) {
    return this.#cache.get(creative_id);
  }

  store(creatives = []) {
    for (const creative of creatives) {
      this.#cache.set(creative.creative_id, creative);

      if (creative.live_id != null) {
        this.#cache.set(creative.live_id, creative);
      }

      if (creative?.settings?.quantum == null) {
        continue;
      }

      this.#handleQuantum(creative);
    }
  }

  #handleQuantum(creative) {
    // mobile
    if (creative.settings.quantum.mobile != null) {
      for (const size of creative.settings.quantum.mobile) {
        const tmp_creative = tmp(creative, size, 0, `${size}xM`);

        this.#cache.set(`${creative.creative_id}_${tmp_creative.quantum}`, tmp_creative);

        if (creative.live_id != null) {
          this.#cache.set(`${creative.live_id}_${tmp_creative.quantum}`, tmp_creative);
        }
      }
    }

    // desktop
    if (creative.settings.quantum.desktop != null || creative.settings.quantum.custom != null) {
      for (const size of [...creative.settings.quantum.desktop, ...creative.settings.quantum.custom]) {
        const tmp_creative = tmp(creative, size, 1, `${size}xD`);

        this.#cache.set(`${creative.creative_id}_${tmp_creative.quantum}`, tmp_creative);

        if (creative.live_id != null) {
          this.#cache.set(`${creative.live_id}_${tmp_creative.quantum}`, tmp_creative);
        }
      }
    }
  }
}
