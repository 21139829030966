<template lang="pug">
div.quantum-preview(v-if="creative.type !== -1 && creative.template_id != null && creative.template != null")
  template(v-if="!resizing" v-for="(devices, device) of screens")
    div.quantum-preview-device
      i.nexd-icon-32-mobile-portrait(v-if="device === 'Mobile'")
      i.nexd-icon-32-desktop(v-else)
      span {{ device }}
    div.row.flex-wrap.gap-16
      template(v-for="size of devices")
        div.col(:key="size.title" :class="{'flex-grow': size.title !== null}")
          div(:style="{width: `${size.el.width}px`}")
            LivePreview(
              ref="preview"
              :key="$route.params.state"
              :creative="creative"
              :height="size.el.height"
              :poll="false"
              :options="{ screen_width: size.width, screen_height: size.height, loading: true, live_id: isLiveCreative() ? creative.live_id : null, wait_for_version: !isLiveCreative() }"
            )
            router-link.fs-14(:to="{ params: { state: 'creative', creative_id: creative.creative_id }, hash: `#${size.title}x${device.slice(0, 1)}`}") {{ size.title }}
</template>

<script>
import Mixins from '@_helpers/Mixins.vue';

import LivePreview from '@master/UI/LivePreview/LivePreview.vue';
import GyroController from '@master/UI/GyroController.vue';

export default {
  name: 'QuantumPreview',

  mixins: [Mixins],

  components: {
    LivePreview,
    GyroController,
  },

  props: {
    creative: Object,
  },

  computed: {
    screens() {
      let screens = {};

      if (this.container_width > 0) {
        const max_width = this.container_width;
        const sizes = this.creative.settings.quantum;

        if (sizes.hasOwnProperty('mobile')) {
          sizes.mobile.forEach(size => {
            if (!screens.hasOwnProperty('Mobile')) {
              screens['Mobile'] = [];
            }

            return screens['Mobile'].push(this.createScreenObject(size, max_width));
          });
        }

        if (sizes.hasOwnProperty('desktop') || sizes.hasOwnProperty('custom')) {
          [...sizes.desktop, ...sizes.custom].forEach(size => {
            if (!screens.hasOwnProperty('Desktop')) {
              screens['Desktop'] = [];
            }

            return screens['Desktop'].push(this.createScreenObject(size, max_width));
          });
        }
      }

      return screens;
    },
  },

  data() {
    return {
      container_width: 0,

      resizing: false,
      resize_timeout: null,
    };
  },

  created() {
    window.addEventListener('resize', this.resizeHandler, false);
  },

  mounted() {
    this.calcContainerWith();
  },

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler, false);
  },

  methods: {
    resizeHandler() {
      this.resizing = true;

      window.clearTimeout(this.resize_timeout);

      this.resize_timeout = window.setTimeout(() => {
        this.calcContainerWith();
        this.resizing = false;
      }, 500);
    },

    calcContainerWith() {
      this.container_width = this.$el.clientWidth - 14;
    },

    createScreenObject(size, max_width) {
      const width = parseInt(size.split('x')[0], 10);
      const height = parseInt(size.split('x')[1], 10);
      const el_width = width > max_width ? max_width : width;
      const el_height = width > max_width ? Math.round(el_width * (height / width)) : height;
      const el = { width: el_width, height: el_height };

      return { width, height, title: size, el };
    },
  },
};
</script>
