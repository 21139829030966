import Subscriber from '@libs/Subscriber';

class QRService extends Subscriber {
  constructor() {
    super();

    this.data = {
      show: false,
      qr: null,
      error: false,
    };
  }

  get() {
    this.#update({
      error: false,
    });

    this.$http
      .post(
        'tools/qr',
        {
          url: `${window.location.origin}/${this.#getParam('state')}/${this.#getParam('creative_id')}`,
        },
        {
          notification: false,
        },
      )
      .then(qr => {
        this.#update({ qr });
      })
      .catch(_ => {
        this.#update({
          error: true,
        });
      });
  }

  toggle() {
    this.#update({
      show: !this.data.show,
    });
  }

  #update(obj) {
    this.data = {
      ...this.data,
      ...obj,
    };
  }

  #getParam(param) {
    return this.router.history.current.params[param];
  }
}

export default new QRService();
